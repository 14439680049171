<template>
  <a
    v-if="hasNavbarLoginButton"
    class="nav-link casino-btn casino-btn-default csn-nav-btn csn-nav-btn-login"
    @click="openLoginModal"
  >
    {{ t('login_btn_label') }}
  </a>
</template>

<script>
import { mapGetters } from 'vuex'
import { NAVBER__LOGIN_BUTTON, Module, IS_LOGIN_PAGE } from '@/constants'
import { dest, openLoginModal } from '@/helpers'

export default {
  name: NAVBER__LOGIN_BUTTON,
  data: () => ({ hasNavbarLoginButton: true }),
  computed: {
    ...mapGetters({ isLoginPage: dest([Module.LOCATION, IS_LOGIN_PAGE]) }),
    t() {
      return this.$createComponentTranslator(NAVBER__LOGIN_BUTTON)
    },
  },
  watch: {
    isLoginPage: {
      immediate: true,
      handler(isLoginPage) {
        if (isLoginPage && this.hasNavbarLoginButton) {
          this.hasNavbarLoginButton = false
        }
        if (!isLoginPage && !this.hasNavbarLoginButton) {
          this.hasNavbarLoginButton = true
        }
      },
    },
  },
  methods: {
    openLoginModal,
  },
}
</script>
